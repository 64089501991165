import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { ArrivedBuilderModels, FetchEntriesParams, fetchEntries } from '@arrived/builder.io';
import { CONFIG } from '@arrived/config';
import { UseAwaitedInfiniteQueryOptions, UseAwaitedQueryOptions } from '@arrived/queries';

import { BuilderBlogArticleModel } from '../models';

import { builderArticlesByCategoryQueryKey } from './keys';

export type FetchArticlesByCategoryQuery = {
  /**
   * @default 9
   */
  limit?: number;
  offset?: number;
  categories?: string[] | null;
  enabled?: boolean;
} & FetchEntriesParams;

export const fetchArticlesByCategory = async ({
  categories,
  offset = 0,
  limit = 9,
  ...rest
}: FetchArticlesByCategoryQuery) =>
  fetchEntries<BuilderBlogArticleModel>({
    apiKey: CONFIG.builderApiKey,
    model: ArrivedBuilderModels.BLOG_ARTICLE,

    omit: 'data.blocks,data.content',
    enrich: true,

    offset,
    limit,

    sort: {
      createdDate: -1,
    },
    // If we send an empty array, we know we are getting all the
    // latest articles, so we don't need to filter by category
    ...(Array.isArray(categories) &&
      categories.length > 0 && {
        query: {
          data: {
            categories: {
              $elemMatch: {
                'category.id': {
                  $in: categories,
                },
              },
            },
          },
        },
      }),
    ...rest,
  });

type UseGetArticlesByCategoryQueryOptions = {
  categoryIds: string[];
  pageParam?: number;
};

// For when we figure out how to support builder.io pagination and knowing the number of results back
export const useGetArticlesByCategoryQuery = (
  query: UseGetArticlesByCategoryQueryOptions,
  options?: UseAwaitedInfiniteQueryOptions<typeof fetchArticlesByCategory>,
) => {
  // Doing 9 as we use rows of 3, this makes it a nice even number of rows
  const ITEM_LIMIT = 9;
  const OFFSET = ITEM_LIMIT + 1;

  // Subtract 1 because we technically start from `0` and moving to page `2` actually means we start at `1`
  const pageOffset = (Number(query.pageParam ?? 1) - 1) * ITEM_LIMIT;

  const params = {
    categories: query.categoryIds,
    offset: pageOffset,
    limit: ITEM_LIMIT,
  };

  return useInfiniteQuery({
    initialPageParam: 0,
    queryKey: builderArticlesByCategoryQueryKey({
      ...params,
      offset: pageOffset,
      limit: ITEM_LIMIT,
      OFFSET,
    }),
    queryFn: ({ pageParam }) =>
      fetchArticlesByCategory({
        ...params,
        offset: pageParam ? Number(pageParam) * OFFSET : pageOffset,
        limit: ITEM_LIMIT,
      }),
    getNextPageParam: (lastPage, _, lastPageParam) => {
      const numberLastPageParam = Number(lastPageParam);
      return lastPage.length < ITEM_LIMIT ? undefined : numberLastPageParam + 1;
    },
    ...options,
    select: (data) => data.pages.flat(),
  });
};

/**
 * Strictly used to get the number of articles in a given category,
 * the max that we can query from builder is ~1000, so we're using
 * this to get the total number of articles in a given category. There is talk
 * to allow this to be part of the metadata returned from `fetchEntries` but
 * that's not happening yet.
 *
 * @see https://forum.builder.io/t/get-total-count-of-models-in-a-builder-io-query/3994
 */
export const useGetTotalArticleCountByCategoryQuery = (
  query: UseGetArticlesByCategoryQueryOptions,
  options?: UseAwaitedQueryOptions<typeof fetchArticlesByCategory>,
) => {
  const params = {
    categories: query.categoryIds,
    limit: 1000,
    offset: 0,
    fields: 'id',
  };

  return useQuery({
    queryKey: builderArticlesByCategoryQueryKey(params),
    queryFn: () => fetchArticlesByCategory(params),
    enabled: Boolean(query.categoryIds),
    ...options,
    select: (data) => data?.length ?? 0,
  });
};
