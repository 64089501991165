import { FormattedMessage } from 'react-intl';

import { Button, Stack } from '@arrived/bricks';
import { BuilderButtonCallToActionModel, GenericBuilderIcons, RegisteredComponent } from '@arrived/builder.io';
import { Link } from '@arrived/router';

export const BrowsePropertiesCTA = ({ text }: Partial<Pick<BuilderButtonCallToActionModel, 'text'>>) => {
  return (
    <Stack marginTop="$4">
      <Link href="/properties">
        <Button
          $platform-native={{
            pointerEvents: 'none',
          }}
        >
          {text ?? <FormattedMessage id="common.browse-investments" />}
        </Button>
      </Link>
    </Stack>
  );
};

BrowsePropertiesCTA.registerComponent = {
  name: 'BrowsePropertiesCTA',
  component: BrowsePropertiesCTA,
  image: GenericBuilderIcons.ButtonPress,
  inputs: [
    {
      name: 'text',
      type: 'text',
      defaultValue: 'Browse Investments',
    },
  ],
} satisfies RegisteredComponent;
