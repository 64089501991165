import { useQuery } from '@tanstack/react-query';

import { ArrivedBuilderModels, fetchEntries } from '@arrived/builder.io';
import { CONFIG } from '@arrived/config';

import { BuilderBlogArticleModel } from '../models';

import { builderRelatedArticlesKeyFn } from './keys';

type RelatedArticleQuery = {
  articleId: string;
  categoryId: string;

  /**
   * @default 3
   */
  limit?: number;
};

export const fetchRelatedArticles = ({ articleId, categoryId, limit = 3 }: RelatedArticleQuery) =>
  fetchEntries<BuilderBlogArticleModel>({
    apiKey: CONFIG.builderApiKey,
    model: ArrivedBuilderModels.BLOG_ARTICLE,
    sort: {
      createdDate: -1,
    },
    enrich: true,
    omit: 'data.content,data.blocks',
    limit,
    query: {
      data: {
        categories: {
          $elemMatch: {
            'category.id': {
              $eq: categoryId,
            },
          },
        },
      },
      // Exclude the current article
      id: {
        $neq: articleId,
      },
    },
  });

export const useGetRelatedArticlesQuery = (params: RelatedArticleQuery) =>
  useQuery({
    queryKey: builderRelatedArticlesKeyFn(params),
    queryFn: () => fetchRelatedArticles(params),
    enabled: Boolean(params),
  });

export type UseGetRelatedArticlesQueryResult = ReturnType<typeof useGetRelatedArticlesQuery>;
