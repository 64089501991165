import { styled, withStaticProperties } from '@tamagui/core';

import { Stack } from '../layout';

const ContainerMain = styled(Stack, {
  name: 'ContainerMain',
  p: 0,
  bg: '$surface.neutral.default',
  flex: 1,
  alignItems: 'center',
  $gtSm: { p: '$4' },
  variants: {
    maxSize: {
      lg: {
        maxWidth: 1440,
      },
    },
  } as const,
});

const ContainerInner = styled(Stack, {
  name: 'ContainerInner',
  bg: '$foundation.neutral.yang',
  width: '100%',
  maxWidth: 1440,
  flex: 1,
  $gtSm: { br: '$2' },
});

const ContainerBar = styled(Stack, {
  name: 'ContainerBar',
  row: true,
  zIndex: '$overlay',
  bg: '$onSurface.neutral.zebra',
  borderBottomWidth: '$0.25',
  borderBottomColor: '$onSurface.neutral.outlineAlt',
  px: '$6',
  py: '$3',
  borderTopLeftRadius: '$2',
  borderTopRightRadius: '$2',
});

export const Container = withStaticProperties(ContainerMain, {
  Inner: ContainerInner,
  Bar: ContainerBar,
});
