import { FormattedMessage } from 'react-intl';

import { BodyText, Stack, TitleText, UtilityText } from '@arrived/bricks';
import { htmlParser } from '@arrived/bricks-common';
import { BricksBuilderIcons, RegisteredComponent } from '@arrived/builder.io';

type DisclaimerSectionProps = {
  disclaimers: string;
  disclaimerLink?: {
    text: string;
    url: string;
  };
};

export const DisclaimerSection = ({ disclaimers }: DisclaimerSectionProps) => {
  return (
    <Stack gap="$3" marginVertical="$4">
      <TitleText tag="h2" token="title.heading.medium">
        <FormattedMessage id="blog.blog-page.disclaimers.title" />
      </TitleText>
      <BodyText>{htmlParser(disclaimers)}</BodyText>
      <UtilityText
        token="utility.hyperlink.medium"
        tag="a"
        href="/communications-disclaimer"
        target="_blank"
        // @ts-expect-error -- rel on an 'a' tag works but does not pass type checking
        rel="noreferrer"
      >
        <FormattedMessage id="blog.blog-page.disclaimers.link.text" />
      </UtilityText>
    </Stack>
  );
};

DisclaimerSection.registerComponent = {
  component: DisclaimerSection,
  name: 'DisclaimerSection',
  image: BricksBuilderIcons.Warning,
  inputs: [
    {
      name: 'disclaimers',
      type: 'richText',
    },
  ],
} satisfies RegisteredComponent;
