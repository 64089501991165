import { BodyText, Card, Count, Stack, TitleText } from '@arrived/bricks';

import { useGetCategoryQuery } from '../queries';

export const CategorySlugHeaderCard = ({
  name,
  description,
  count,
}: NonNullable<NonNullable<ReturnType<typeof useGetCategoryQuery>['data']>['data']> & {
  count?: number;
}) => {
  return (
    <Card>
      <Stack p="$4" $gtXs={{ p: '$6' }} flex={1}>
        <Stack row gap="$2">
          <TitleText
            token="title.heading.medium"
            color="$onSurface.neutral.outlineAlt"
            $gtXs={{
              token: 'title.heading.large',
            }}
          >
            {name}
          </TitleText>
          {Boolean(count && count > 0) && <Count variant="inverted">{count}</Count>}
        </Stack>
        <BodyText
          token="body.default.small"
          color="$onSurface.neutral.outlineAlt"
          $gtXs={{ token: 'body.default.medium' }}
        >
          {description}
        </BodyText>
      </Stack>
    </Card>
  );
};
