import { PropsWithChildren, useCallback } from 'react';
import { Text } from 'react-native';

import { useIntercom } from 'react-use-intercom';

export const IntercomHelpCenter = ({ children }: PropsWithChildren) => {
  const intercom = useIntercom();

  const handleTextPress = useCallback(() => {
    intercom.showSpace('help');
  }, [intercom]);

  return <Text onPress={handleTextPress}>{children}</Text>;
};
