import { PropsWithChildren } from 'react';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useArrivedAuth0 } from '@arrived/arrived-auth0';
import { Container } from '@arrived/bricks';
import { useVerificationStepperContext } from '@arrived/features-verification-stepper';

export const BlogContainerBar = ({ children }: PropsWithChildren) => {
  const insets = useSafeAreaInsets();
  const { isShowing } = useVerificationStepperContext();
  const { isAuthenticated } = useArrivedAuth0();

  return (
    <Container.Bar
      row={false}
      justifyContent="space-between"
      gap="$4"
      position="unset"
      // Overwrite to fall under the banners -- this
      // should work with any of our container bars but isn't laying
      // out correctly with the new navigation bar? @kaceycleveland any thoughts?
      zIndex="$dropdown"
      $platform-web={{
        position: 'sticky',
        top: 0,
      }}
      $platform-native={{
        gap: '$2',
        px: '$4',
        pb: '$2',
        pt: isAuthenticated && !isShowing ? insets.top : '$2',
      }}
    >
      {children}
    </Container.Bar>
  );
};
