import { useMemo } from 'react';

import { TitleText, TitleTextProps } from '@arrived/bricks';
import { RegisteredComponent } from '@arrived/builder.io';

export type ArticleSectionProps = {
  title: string;
  id: string;
  level: 1 | 2 | 3 | 4;
};

export const ArticleSection = ({ title, id, level }: ArticleSectionProps) => {
  const tag = useMemo<TitleTextProps['tag']>(() => {
    switch (level) {
      case 1:
        return 'h2';
      case 2:
        return 'h3';
      case 3:
        return 'h4';
      case 4:
        return 'h5';
    }
  }, [level]);

  const token = useMemo<TitleTextProps['token']>(() => {
    switch (level) {
      case 1:
        return 'title.heading.xlarge';
      case 2:
        return 'title.heading.large';
      case 3:
        return 'title.heading.medium';
      case 4:
        return 'title.heading.small';
    }
  }, [level]);

  return (
    <TitleText tag={tag} id={id} token={token} $platform-web={{ scrollMarginTop: '80px' /* height of navbar */ }}>
      {title}
    </TitleText>
  );
};

export const ARTICLE_SECTION_ID = 'article-section-id';

ArticleSection.registerComponent = {
  component: ArticleSection,
  name: 'Article Section',
  inputs: [
    {
      name: 'builder_id',
      type: 'text',
      defaultValue: ARTICLE_SECTION_ID,
      helperText:
        'This is used to render the table of contents for a blog; most likely, you do not need to touch this at all!.',
      advanced: true,
    },
    {
      name: 'title',
      type: 'text',
      required: true,
    },
    {
      name: 'id',
      type: 'text',
      required: true,
    },
    {
      name: 'level',
      type: 'enum',
      enum: [
        {
          label: '1',
          value: 1,
        },
        {
          label: '2',
          value: 2,
        },
        {
          label: '3',
          value: 3,
        },
        {
          label: '4',
          value: 4,
        },
      ],
    },
  ],
} satisfies RegisteredComponent;
