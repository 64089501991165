import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function SearchIconInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
      <Path
        d="M9.16666 16.6666C13.3088 16.6666 16.6667 13.3088 16.6667 9.16663C16.6667 5.02449 13.3088 1.66663 9.16666 1.66663C5.02453 1.66663 1.66666 5.02449 1.66666 9.16663C1.66666 13.3088 5.02453 16.6666 9.16666 16.6666Z"
        stroke={color}
        strokeWidth="1.25"
      />
      <Path d="M14.5064 14.506L18.3334 18.3333" stroke={color} strokeWidth="1.25" />
    </Svg>
  );
}

export const SearchIcon = memo<IconProps>(withIconTheme(SearchIconInternal));
