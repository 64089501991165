import { Stack, TitleText, createStyledContext, styled, withStaticProperties } from '@arrived/bricks';

const BLOG_POST_LIST_NAME = 'ArticleList';
const BLOG_POST_LIST_HEADER_NAME = `${BLOG_POST_LIST_NAME}Header`;
const BLOG_POST_LIST_TITLE_NAME = `${BLOG_POST_LIST_NAME}Title`;
const BLOG_POST_LIST_TAGS_NAME = `${BLOG_POST_LIST_NAME}Tags`;
const BLOG_POST_LIST_FILTERS_NAME = `${BLOG_POST_LIST_NAME}Filters`;
const BLOG_POST_LIST_BODY_NAME = `${BLOG_POST_LIST_NAME}Body`;
const BLOG_POST_LIST_ITEMS_NAME = `${BLOG_POST_LIST_NAME}Items`;
const BLOG_POST_LIST_FOOTER_NAME = `${BLOG_POST_LIST_NAME}Footer`;

export const ArticleListStyledContext = createStyledContext({
  horizontal: false,
});

const ArticleListFrame = styled(Stack, {
  name: BLOG_POST_LIST_NAME,
  context: ArticleListStyledContext,

  gap: '$4',

  variants: {
    horizontal: {
      true: {},
    },
  } as const,
});

const ArticleListTitle = styled(TitleText, {
  name: BLOG_POST_LIST_TITLE_NAME,
  context: ArticleListStyledContext,

  token: 'title.heading.small',
});

const ArticleListTags = styled(Stack, {
  name: BLOG_POST_LIST_TAGS_NAME,
  context: ArticleListStyledContext,
});

const ArticleListFilters = styled(Stack, {
  name: BLOG_POST_LIST_FILTERS_NAME,
  context: ArticleListStyledContext,
});

const ArticleListHeaderFrame = styled(Stack, {
  name: BLOG_POST_LIST_HEADER_NAME,
  context: ArticleListStyledContext,
});
const ArticleListHeader = withStaticProperties(ArticleListHeaderFrame, {
  Title: ArticleListTitle,
  Tags: ArticleListTags,
  Filters: ArticleListFilters,
});

const ArticleListItems = styled(Stack, {
  name: BLOG_POST_LIST_ITEMS_NAME,
  context: ArticleListStyledContext,

  gap: '$4',
  justifyContent: 'space-between',

  variants: {
    horizontal: {
      true: {
        row: true,
      },
    },
  } as const,
});

const ArticleListBodyFrame = styled(Stack, {
  name: BLOG_POST_LIST_BODY_NAME,
  context: ArticleListStyledContext,
});
const ArticleListBody = withStaticProperties(ArticleListBodyFrame, {
  Items: ArticleListItems,
});

const ArticleListFooter = styled(Stack, {
  name: BLOG_POST_LIST_FOOTER_NAME,
  context: ArticleListStyledContext,
});

export const ArticleList = withStaticProperties(ArticleListFrame, {
  Header: ArticleListHeader,
  Body: ArticleListBody,
  Footer: ArticleListFooter,
});
