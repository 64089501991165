import { useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { CaretDownIcon, Divider, InfoBox, Stack, UtilityText, isWeb } from '@arrived/bricks';
import { TextLink } from '@arrived/router';

export interface ArticleNavigationEntry {
  name: string;
  id: string;
}

export interface BlogSectionProps {
  sections: ArticleNavigationEntry[];
}

export const ArticleNavigation = ({ sections }: BlogSectionProps) => {
  const intl = useIntl();
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      {isWeb && (
        <style jsx global>{`
          ol.blog-toc {
            padding-left: 0;
            list-style-position: inside;
          }

          ol.blog-toc li.article-section-link {
            display: list-item;
          }
        `}</style>
      )}

      <InfoBox
        tag="nav"
        aria-label={intl.formatMessage({ id: 'blog.article-navigation.aria-label' })}
        condensed
        $gtXxs={{ condensed: false }}
      >
        <Stack
          row
          justifyContent="space-between"
          cursor="pointer"
          $gtXxs={{ cursor: 'none' }}
          onPress={() => setExpanded(!expanded)}
        >
          <UtilityText tag="h2" token="utility.label.small">
            <FormattedMessage id="blog.blog-page.sections.title" />
          </UtilityText>

          <Stack $gtXxs={{ display: 'none' }} rotate={expanded ? '180deg' : '0deg'} animation="quick">
            <CaretDownIcon />
          </Stack>
        </Stack>

        <Stack
          tag="ul"
          my={0}
          pt={expanded ? '$4' : 0}
          paddingLeft={0}
          height={expanded ? 'auto' : 0}
          overflow="hidden"
          $gtXxs={{ height: 'auto', overflow: 'visible', pt: '$4' }}
          className="blog-toc"
        >
          {sections.map((entry, idx) => (
            <Stack tag="li" key={idx}>
              <TextLink href={`#${entry.id}`} prefetch={false}>
                <UtilityText className="section-entry" token="utility.hyperlink.large">
                  {entry.name}
                </UtilityText>
              </TextLink>

              {idx !== sections.length - 1 && <Divider solid alt py="$3" />}
            </Stack>
          ))}
        </Stack>
      </InfoBox>
    </>
  );
};
