import { useQuery } from '@tanstack/react-query';

import { ArrivedBuilderModels, FetchEntriesParams, fetchEntries } from '@arrived/builder.io';
import { CONFIG } from '@arrived/config';

import { BuilderBlogArticleModel } from '../models';

import { builderRecentArticlesKeyFn } from './keys';

type RecentArticlesQuery = {
  /**
   * @default 3
   */
  limit?: number;
} & FetchEntriesParams;

export const fetchRecentArticles = ({ limit = 3, ...options }: RecentArticlesQuery) =>
  fetchEntries<BuilderBlogArticleModel>({
    apiKey: CONFIG.builderApiKey,
    model: ArrivedBuilderModels.BLOG_ARTICLE,
    limit,
    ...options,
  });

export const useGetRecentArticlesQuery = (params: RecentArticlesQuery) =>
  useQuery({
    queryKey: builderRecentArticlesKeyFn(params),
    queryFn: () => fetchRecentArticles(params),
  });

export type UseGetRecentArticlesQueryResult = ReturnType<typeof useGetRecentArticlesQuery>;
