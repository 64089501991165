import { ArrivedBuilderModels, FetchEntriesParams, FetchOneEntryParams, builderQueryKey } from '@arrived/builder.io';

export const builderCategoriesSymbolQueryKey = (params: FetchEntriesParams | null = null) =>
  [...builderQueryKey, ArrivedBuilderModels.CATEGORIES, params] as const;

export const builderCategoryQueryKey = ({
  categorySlug,
  ...options
}: {
  categorySlug?: string;
} & FetchOneEntryParams) =>
  [...builderQueryKey, ArrivedBuilderModels.CATEGORIES, 'category', categorySlug, options] as const;

export const builderCategoriesQueryKey = (
  params:
    | ({
        categorySlug?: string;
      } & FetchOneEntryParams)
    | null = null,
) => [...builderQueryKey, ArrivedBuilderModels.CATEGORIES, params] as const;

export const builderArticlesByCategoryQueryKey = (params: Record<string, unknown>) =>
  [...builderQueryKey, ArrivedBuilderModels.BLOG_ARTICLE, 'category', params] as const;

export const builderBlogArticleQueryKeyFn = (slug: string = '') =>
  [...builderQueryKey, ArrivedBuilderModels.BLOG_ARTICLE, slug] as const;

export const builderPageQueryFn = ({ urlPath, ...options }: { urlPath: string } & FetchOneEntryParams) =>
  [...builderQueryKey, ArrivedBuilderModels.PAGE, urlPath, options] as const;

export const builderWebinarQueryKeyFn = (params: FetchEntriesParams) =>
  [...builderQueryKey, ArrivedBuilderModels.WEBINAR, params] as const;

export const builderSymbolsQueryKeyFn = (query: Record<string, unknown>) =>
  [...builderQueryKey, 'symbol', query] as const;

export const builderRelatedArticlesKeyFn = (query: Record<string, unknown>) =>
  [...builderQueryKey, 'related', ArrivedBuilderModels.BLOG_ARTICLE, query] as const;

export const builderRecentArticlesKeyFn = (params: FetchEntriesParams) =>
  [...builderQueryKey, 'recent', ArrivedBuilderModels.BLOG_ARTICLE, params] as const;

export const builderSearchArticlesKeyFn = (params: Record<string, unknown>) =>
  [...builderQueryKey, 'search', params] as const;
