import { BodyText, Button, Card, Stack, TitleText, isWeb } from '@arrived/bricks';
import { Href } from '@arrived/bricks-common';
import {
  BricksBuilderIcons,
  BuilderButtonCallToActionModel,
  BuilderImage,
  RegisteredComponent,
} from '@arrived/builder.io';
import { useLink } from '@arrived/router';

type WebinarBlogWidgetProps = {
  title: string;
  description?: string;
  featuredHosts?: {
    hostName: string;
    headshot: string;
  }[];
  watchRecordingsCTA: BuilderButtonCallToActionModel;
  registerCTA: BuilderButtonCallToActionModel;
};

export const WebinarBlogWidget = ({
  title,
  description,
  featuredHosts,
  watchRecordingsCTA,
  registerCTA,
}: WebinarBlogWidgetProps) => {
  const watchRecordingsLink = useLink({
    href: watchRecordingsCTA.url,
  });

  const registerLink = useLink({
    href: registerCTA.url,
  });

  return (
    <Card borderRadius="$2">
      <Stack
        gap="$6"
        p="$4"
        $gtSm={{
          p: '$6',
        }}
        $gtMd={{
          p: '$8',
        }}
        centered
      >
        {featuredHosts && (
          <Stack gap="$4" row centered>
            {featuredHosts.map(({ hostName, headshot }, index) => {
              return (
                <Stack key={`${index}-${hostName}`} width={56} height={56} overflow="hidden" borderRadius={100}>
                  <BuilderImage
                    aspectRatio="1"
                    alt={`${hostName}'s headshot`}
                    source={{
                      uri: headshot,
                    }}
                    contentFit="contain"
                  />
                </Stack>
              );
            })}
          </Stack>
        )}

        <Stack gap="$2">
          <TitleText
            tag="h3"
            textAlign="center"
            token="title.heading.medium"
            color="$onSurface.neutral.defaultInverted"
            $gtXs={{
              token: 'title.heading.large',
            }}
          >
            {title}
          </TitleText>

          {Boolean(description) && (
            <BodyText
              token="body.default.medium"
              color="$onSurface.neutral.defaultInverted"
              textAlign="center"
              maxWidth={490}
            >
              {description}
            </BodyText>
          )}
        </Stack>

        <Stack gap="$2" centered row>
          <Button variant="ghost" inverted {...watchRecordingsLink}>
            {watchRecordingsCTA.text}
          </Button>

          {/* TODO: This is a hack, we need to add the ability to link via `useLink` on native to in-app browsers. This has been noted in `@arrived/router` */}

          {isWeb ? (
            <Button variant="outlined" inverted {...registerLink}>
              {registerCTA.text}
            </Button>
          ) : (
            <Href href={registerCTA.url} as={Button} variant="outlined" inverted>
              {registerCTA.text}
            </Href>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

WebinarBlogWidget.registerComponent = {
  component: WebinarBlogWidget,
  name: 'WebinarBlogWidget',
  image: BricksBuilderIcons.ArrivedLogoWhite,
  inputs: [
    { name: 'title', type: 'longText', required: true },
    { name: 'description', type: 'longText' },
    {
      name: 'featuredHosts',
      type: 'list',
      subFields: [
        { name: 'hostName', type: 'longText', required: true },
        { name: 'headshot', type: 'file', required: true },
      ],
    },
    {
      name: 'watchRecordingsCTA',
      friendlyName: 'Watch Recordings CTA',
      type: 'object',
      helperText: 'You must provide both the CTA text and url for the button to be visible',
      subFields: [
        {
          name: 'text',
          type: 'text',
          required: true,
        },
        {
          name: 'url',
          type: 'url',
          required: true,
        },
      ],
    },
    {
      name: 'registerCTA',
      friendlyName: 'Register CTA',
      type: 'object',
      helperText: 'You must provide both the CTA text and url for the button to be visible',
      subFields: [
        {
          name: 'text',
          type: 'text',
          required: true,
        },
        {
          name: 'url',
          type: 'url',
          required: true,
        },
      ],
    },
  ],
  models: ['symbol'],
} satisfies RegisteredComponent;
