import { Dispatch, PropsWithChildren, SetStateAction, useEffect, useState } from 'react';

import { createUseContext } from '@arrived/contexts';

export type CategoriesNavigationContextType = {
  activeCategorySlug: string;
  setActiveCategorySlug: Dispatch<SetStateAction<string | undefined>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
};

export const [CategoriesNavigationProvider, useCategoriesNavigationContext, CategoriesNavigationContext] =
  createUseContext<CategoriesNavigationContextType, PropsWithChildren>(
    {
      activeCategorySlug: 'blog',
      page: 1,
    } as CategoriesNavigationContextType,
    (Provider) =>
      ({ children }) => {
        const [activeCategorySlug, setActiveCategorySlug] = useState<string>('blog');
        const [page, setPage] = useState(1);

        useEffect(() => {
          // Reset page when category changes
          setPage(1);
        }, [activeCategorySlug]);

        return <Provider value={{ activeCategorySlug, setActiveCategorySlug, page, setPage }}>{children}</Provider>;
      },
  );
