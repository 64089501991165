import { useInfiniteQuery } from '@tanstack/react-query';

import { ArrivedBuilderModels, FetchEntriesParams, fetchEntries } from '@arrived/builder.io';
import { CONFIG } from '@arrived/config';
import { UseAwaitedInfiniteQueryOptions } from '@arrived/queries';

import { BuilderBlogArticleModel } from '../models';

import { builderSearchArticlesKeyFn } from './keys';

export type SearchArticlesQuery = {
  searchStr: string;
  enabled?: boolean;
} & FetchEntriesParams;

/**
 * Fetches all articles that match the "searchStr", it
 * adds `_` and `-` to the searchStr to support searching
 * for words with spaces or slugs with dashes.
 *
 * @see https://www.builder.io/c/docs/querying
 */
export const fetchSearchArticles = ({ searchStr, offset = 0, limit = 10, ...rest }: SearchArticlesQuery) => {
  // Also add - and _ to the white space and concat them to the searchStr
  const escapeStr = searchStr.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const slugSearchStr = searchStr.replace(/ /g, '-');
  const keywordSearchStr = searchStr.replace(/_/g, '-');

  // We are making a search for all of these strings in order to support
  // a semi-fuzzy search.
  const regexSupportedStr = `${escapeStr}|${slugSearchStr}|${keywordSearchStr}`;

  return fetchEntries<BuilderBlogArticleModel>({
    apiKey: CONFIG.builderApiKey,
    model: ArrivedBuilderModels.BLOG_ARTICLE,

    omit: 'data.blocks',

    sort: {
      createdDate: -1,
    },

    enrich: true,

    query: {
      data: {
        $or: [
          {
            title: {
              // https://www.mongodb.com/docs/v4.4/reference/operator/query/regex/
              $regex: regexSupportedStr,
              $options: 'i', // x: extended regex, i: case insensitive
            },
          },
          {
            excerpt: {
              $regex: regexSupportedStr,
              $options: 'i',
            },
          },
          {
            content: {
              $regex: regexSupportedStr,
              $options: 'i',
            },
          },
          {
            slug: {
              $regex: regexSupportedStr,
              $options: 'i',
            },
          },
          {
            categories: {
              $elemMatch: {
                'category.data.name': {
                  $regex: regexSupportedStr,
                  $options: 'i',
                },
              },
            },
          },
        ],
      },
    },
    fields: 'id,previewUrl,data.title,data.excerpt,data.featuredImage,data.categories,data.slug',
    limit,
    offset,
    ...rest,
  });
};

export const useSearchArticlesQuery = (
  query: string,
  options?: UseAwaitedInfiniteQueryOptions<typeof fetchSearchArticles>,
) => {
  const ITEM_LIMIT = 10;
  const OFFSET = ITEM_LIMIT + 1;

  return useInfiniteQuery({
    initialPageParam: 0,
    queryKey: builderSearchArticlesKeyFn({ query, OFFSET, ITEM_LIMIT }),
    queryFn: ({ pageParam = 0 }) =>
      fetchSearchArticles({ searchStr: query, offset: Number(pageParam) * OFFSET, limit: ITEM_LIMIT }),
    getNextPageParam: (lastPage, __, lastPageParam) => {
      const numberLastPageParam = Number(lastPageParam);
      return lastPage.length < ITEM_LIMIT ? undefined : numberLastPageParam + 1;
    },
    ...options,
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    // Override select to return the flattened array of pages
    select: (data) => data.pages.flat(),
  });
};
