import { Keyboard } from 'react-native';

import { FormattedMessage } from 'react-intl';

import { Button, SearchIcon, useDisclosure, useMedia } from '@arrived/bricks';

import { SearchSheet } from './SearchSheet';

export const SearchButton = () => {
  const { onOpen, onClose, isOpen } = useDisclosure({
    onClose: () => {
      Keyboard.dismiss();
    },
  });

  const media = useMedia();

  return (
    <>
      <Button condensed variant="ghost" Icon={SearchIcon} iconPosition="after" onPress={onOpen}>
        {media.gtXs && <FormattedMessage id="common.search" />}
      </Button>
      <SearchSheet onClose={onClose} open={isOpen} />
    </>
  );
};
