import { useCallback, useDeferredValue, useEffect, useRef, useState } from 'react';
import { Keyboard, LayoutAnimation, TextInput } from 'react-native';

import { FormattedMessage } from 'react-intl';
import { useSoftInputHeightChanged } from 'react-native-avoid-softinput';
import Animated, { FadeInDown, FadeOut, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';

import { FlashList } from '@shopify/flash-list';
import { useDebounce } from 'usehooks-ts';

import {
  AnimatePresence,
  BlurView,
  Button,
  Divider,
  EmptyState,
  Image,
  Input,
  Loading,
  SearchIcon,
  Sheet,
  SheetProps,
  Stack,
  isWeb,
  useMedia,
  useSheetImperativeContext,
} from '@arrived/bricks';
import { RefreshControl } from '@arrived/bricks-common';
import { ikClient } from '@arrived/imagekit';
import { useRouter } from '@arrived/router';

import { useSearchArticlesQuery } from '../../queries';
import { ArticleCard } from '../article';

const magnifyingGlassUri = ikClient.url({
  path: '/bricks/invest/invest.no.properties.png',
  transformation: [{ width: '280', height: '280' }],
});

const SearchSheetContent = () => {
  const inputRef = useRef<TextInput>(null);
  const media = useMedia();
  const router = useRouter();

  const { open, onClose } = useSheetImperativeContext();

  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, isWeb ? 625 : 1250); // On web we can be much more lenient with debouncing
  const deferredSearchQuery = useDeferredValue(debouncedSearchQuery);

  const searchQueryState = useSearchArticlesQuery(debouncedSearchQuery);

  // This is a ref to the search list so we can use it to animate the list
  const searchListRef = useRef<FlashList<NonNullable<typeof searchQueryState.data>[number]>>(null);

  const debouncedSearchQueryIsEmpty = useDeferredValue(searchQueryState.data?.length === 0);

  const handleEndReached = useCallback(() => {
    if (searchQueryState.hasNextPage && !searchQueryState.isFetchingNextPage) {
      searchQueryState.fetchNextPage();
    }
  }, [searchQueryState]);

  const buttonContainerPaddingValue = useSharedValue(0);

  const buttonContainerAnimatedStyle = useAnimatedStyle(
    () => ({
      paddingBottom: buttonContainerPaddingValue.value,
    }),
    [buttonContainerPaddingValue.value],
  );

  useSoftInputHeightChanged(({ softInputHeight }) => {
    buttonContainerPaddingValue.value = withTiming(softInputHeight);
  });

  useEffect(() => {
    setSearchQuery('');

    if (open) {
      inputRef.current?.focus();
    }

    return () => {
      // For native, force dismiss the keyboard when the sheet closes
      // as it doesn't always dismiss when the sheet closes
      Keyboard.dismiss();
    };
  }, [open]);

  useEffect(() => {
    if (!isWeb) {
      searchListRef.current?.prepareForLayoutAnimationRender();
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    }
  }, [deferredSearchQuery, searchQueryState.isFetchingNextPage]);

  return (
    <>
      <Stack px="$4" pt="$4" pb="$1" $gtXxs={{ px: '$6' }} $gtSm={{ px: '$10' }}>
        <Input ref={inputRef} onChangeText={setSearchQuery} placeholder="Search articles..." autoFocus={open}>
          <Input.End pr="$4">
            <SearchIcon />
          </Input.End>
        </Input>
      </Stack>

      <Stack flexGrow={1}>
        <Sheet.ScrollView
          keyboardShouldPersistTaps="handled"
          useScrollFade
          refreshControl={!isWeb ? <RefreshControl onRefresh={searchQueryState.refetch} /> : <></>}
        >
          <Animated.View
            style={[buttonContainerAnimatedStyle, { flex: 1 }]}
            entering={FadeInDown.delay(450)}
            exiting={FadeOut}
          >
            {/* This is nested for performance, two scroll views are fine :) */}
            <FlashList
              ref={searchListRef}
              data={searchQueryState.data}
              showsVerticalScrollIndicator={!isWeb}
              estimatedItemSize={275}
              keyExtractor={(item, index) => `${item.id}-${index}`}
              ItemSeparatorComponent={() => <Divider solid alt py="$4" />}
              ListEmptyComponent={
                <AnimatePresence>
                  {!searchQueryState.isFetching && (
                    <EmptyState
                      key="empty-state"
                      animation="smoothSpring"
                      enterStyle={{ opacity: 1 }}
                      exitStyle={{ opacity: 0 }}
                      opacity={1}
                      px="$10"
                      $gtXxs={{ px: '20%' }}
                      centered
                    >
                      <Stack width={140} height={140} centered>
                        <Image
                          source={{ uri: magnifyingGlassUri }}
                          alt="Magnifying Glass - Empty Search Results"
                          aspectRatio="4/3"
                          contentFit="contain"
                        />
                      </Stack>
                      <EmptyState.Text>
                        {debouncedSearchQuery !== '' ? (
                          <FormattedMessage id="blog.search.no-results" values={{ query: debouncedSearchQuery }} />
                        ) : (
                          <FormattedMessage id="blog.search.explore" />
                        )}
                      </EmptyState.Text>
                    </EmptyState>
                  )}
                </AnimatePresence>
              }
              extraData={{
                gtSm: media.gtSm,
              }}
              ListFooterComponent={
                <Stack py="$4">
                  {searchQueryState.hasNextPage && (
                    <Button variant="outlined" loading={searchQueryState.isFetchingNextPage} onPress={handleEndReached}>
                      <FormattedMessage id="common.load-more" />
                    </Button>
                  )}
                </Stack>
              }
              renderItem={({ item, extraData }) => (
                <ArticleCard
                  horizontal
                  variant="preview"
                  tag="a"
                  href={`/blog/${item.data?.slug}`}
                  onPress={() => {
                    // On web we need a slight delay to close the sheet
                    if (isWeb) {
                      setTimeout(() => {
                        onClose();
                      }, 300);
                    } else {
                      onClose();
                      router.push(`/blog/${item.data?.slug}`);
                    }
                  }}
                >
                  {item.data?.featuredImage && (
                    <ArticleCard.Image
                      title={item.data?.title}
                      featuredImage={item.data.featuredImage}
                      maxWidth={150}
                      width="100%"
                      flexShrink={0}
                    />
                  )}

                  <Stack gap="$2" flex={1}>
                    <ArticleCard.Header tag="h4" numberOfLines={4}>
                      {item.data?.title}
                    </ArticleCard.Header>

                    {extraData.gtSm && (
                      <ArticleCard.Excerpt numberOfLines={3}>{item.data?.excerpt}</ArticleCard.Excerpt>
                    )}
                  </Stack>
                </ArticleCard>
              )}
            />
          </Animated.View>
        </Sheet.ScrollView>

        <AnimatePresence>
          {searchQueryState.isLoading && !debouncedSearchQueryIsEmpty && (
            <Loading
              key="loading-state"
              animation="smoothSpring"
              enterStyle={{ opacity: 1 }}
              exitStyle={{ opacity: 0 }}
              opacity={1}
              zIndex="$overlay"
              pointerEvents="none"
              fullscreen
              centered
            >
              <BlurView fullscreen intensity={10} />
              <Loading.Indicator variant="colored" />
            </Loading>
          )}
        </AnimatePresence>
      </Stack>
    </>
  );
};

export const SearchSheet = (props: SheetProps) => {
  return (
    <Sheet data-e2e="blog-search-sheet" disablePortal={!isWeb} isNotBottomSheet={!isWeb} variant="skeleton" {...props}>
      <Sheet.Header>
        <Sheet.Header.Title>
          <Sheet.Header.Title.Text>Search</Sheet.Header.Title.Text>
          <Sheet.Header.CloseIcon />
        </Sheet.Header.Title>
      </Sheet.Header>
      <Sheet.Divider />
      <Sheet.Body>
        <SearchSheetContent />
      </Sheet.Body>
    </Sheet>
  );
};
