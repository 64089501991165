import { Dispatch, SetStateAction, useMemo } from 'react';

import { useIntl } from 'react-intl';

import { ArrowLeftIcon, ArrowRightIcon, Button, Stack, isWeb } from '@arrived/bricks';
import { useLink } from '@arrived/router';

import { useGetArticlesByCategoryQuery } from '../queries';

type ArticleListPaginationProps = {
  categoryPostsState: ReturnType<typeof useGetArticlesByCategoryQuery>;
  categorySlug: string;
  pageParam: number;

  /**
   * On native, we update the state instead of paginating via the URL
   *
   * @native
   */
  setPage?: Dispatch<SetStateAction<number>>;
};

export const ArticleListPagination = ({
  pageParam,
  categorySlug,
  setPage,
  categoryPostsState,
}: ArticleListPaginationProps) => {
  const intl = useIntl();

  const basePath = useMemo(() => `/blog/category/${categorySlug}`, [categorySlug]);

  const hasPrevPage = useMemo(() => (pageParam ? pageParam > 1 : false), [pageParam]);
  const prevPage = useMemo(() => (hasPrevPage ? pageParam - 1 : undefined), [hasPrevPage, pageParam]);
  const prevPagePath = useMemo(() => {
    if (prevPage === 1) {
      return basePath;
    }

    return `${basePath}/${prevPage}`;
  }, [basePath, prevPage]);

  const nextPage = useMemo(
    () => (categoryPostsState.hasNextPage ? pageParam + 1 : undefined),
    [categoryPostsState.hasNextPage, pageParam],
  );
  const nextPagePath = useMemo(() => `${basePath}/${nextPage}`, [basePath, nextPage]);

  // On web, we use the `useLink` to generate the correct href
  // On native, we use the `setPage` function to update the state

  const prevPagePathProps = useLink({
    href: prevPagePath,
  });

  const currentPageUrlProps = useLink({
    href: `${basePath}/${pageParam}`,
  });

  const nextPageUrlProps = useLink({
    href: nextPagePath,
  });

  return (
    <Stack row justifyContent="center" gap="$1">
      {prevPage && (
        <Button
          condensed
          variant="ghost"
          Icon={ArrowLeftIcon}
          aria-label={intl.formatMessage({ id: 'common.previous-page' })}
          {...prevPagePathProps}
          onPress={(e) => {
            if (!isWeb) {
              setPage?.(prevPage);
              return;
            }

            prevPagePathProps?.onPress?.(e);
          }}
        />
      )}

      {prevPage && (
        <Button
          condensed
          variant="ghost"
          {...prevPagePathProps}
          onPress={(e) => {
            if (!isWeb) {
              setPage?.(prevPage);
              return;
            }

            prevPagePathProps?.onPress?.(e);
          }}
        >
          {prevPage}
        </Button>
      )}

      <Button
        condensed
        {...currentPageUrlProps}
        onPress={(e) => {
          if (!isWeb) {
            setPage?.(pageParam);
            return;
          }

          currentPageUrlProps?.onPress?.(e);
        }}
      >
        {pageParam}
      </Button>

      {nextPage && (
        <Button
          condensed
          variant="ghost"
          {...nextPageUrlProps}
          onPress={(e) => {
            if (!isWeb) {
              setPage?.(nextPage);
              return;
            }

            nextPageUrlProps?.onPress?.(e);
          }}
        >
          {nextPage}
        </Button>
      )}

      {nextPage && (
        <Button
          condensed
          variant="ghost"
          Icon={ArrowRightIcon}
          aria-label={intl.formatMessage({ id: 'common.next-page' })}
          {...nextPageUrlProps}
          onPress={(e) => {
            if (!isWeb) {
              setPage?.(nextPage);
              return;
            }

            nextPageUrlProps?.onPress?.(e);
          }}
        />
      )}
    </Stack>
  );
};
