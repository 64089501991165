import { useContext, useMemo } from 'react';
import { useWindowDimensions } from 'react-native';

import {
  BodyText,
  GetProps,
  Stack,
  TitleText,
  createStyledContext,
  isWeb,
  styled,
  withStaticProperties,
} from '@arrived/bricks';
import { BuilderImage, transformBuilderImage } from '@arrived/builder.io';

import { BuilderBlogArticleModel } from '../../models';

const ARTICLE_CARD_NAME = 'ArticleCard';
const ARTICLE_CARD_IMAGE_NAME = `${ARTICLE_CARD_NAME}Image`;
const ARTICLE_CARD_HEADER_NAME = `${ARTICLE_CARD_NAME}Header`;
const ARTICLE_CARD_EXCERPT_NAME = `${ARTICLE_CARD_NAME}Excerpt`;

export const ArticleCardStyledContext = createStyledContext({
  variant: null,
});

const ArticleCardFrame = styled(Stack, {
  name: ARTICLE_CARD_NAME,
  context: ArticleCardStyledContext,

  // @ts-expect-error the tamagui types here need updating
  group: 'articleCard',

  gap: '$4',
  flex: 1,

  animation: 'quick',
  pressStyle: {
    opacity: 0.7,
  },

  cursor: 'pointer',
  borderRadius: '$2',
  p: '$2',
  m: -8,

  variants: {
    variant: {
      hero: {
        alignItems: 'flex-start',
      },
      preview: {},
    },
    horizontal: {
      true: {
        row: true,
        alignItems: 'center',
      },
    },
  } as const,
});

const ArticleCardImageFrame = styled(Stack, {
  name: ARTICLE_CARD_IMAGE_NAME,
  context: ArticleCardStyledContext,
});

const ArticleCardImage = ArticleCardImageFrame.styleable<
  GetProps<typeof ArticleCardImageFrame> & Partial<BuilderBlogArticleModel>
>(({ title, featuredImage, className, ...rest }, ref) => {
  const { variant } = useContext(ArticleCardStyledContext);
  const { width } = useWindowDimensions();

  const maxWidth = useMemo(() => {
    if (variant === 'hero') {
      return 800;
    }

    return isWeb ? 500 : width;
  }, [variant, width]);

  const imageUrl = useMemo(
    () => (featuredImage ? transformBuilderImage(featuredImage, { width: maxWidth, height: maxWidth * 0.625 }) : null),
    [featuredImage, maxWidth],
  );

  return (
    <>
      {isWeb && (
        <style jsx global>{`
          .article-image-preview {
            transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
            aspect-ratio: 4 / 2.5;
            max-width: 100%;
            max-height: 100%;
          }
        `}</style>
      )}
      <ArticleCardImageFrame
        ref={ref}
        className="article-image"
        borderRadius="$2"
        overflow="hidden"
        maxHeight="100%"
        {...rest}
      >
        <Stack asChild $group-articleCard-hover={{ scale: 1.03 }} flex={1} width="100%" height="100%">
          <BuilderImage
            className={[className, 'article-image-preview'].filter(Boolean).join(' ')}
            aspectRatio="4/2.5"
            contentFit="fill"
            flex={1}
            source={{
              uri: imageUrl!,
            }}
            alt={title}
          />
        </Stack>
      </ArticleCardImageFrame>
    </>
  );
});

const ArticleCardHeader = styled(TitleText, {
  name: ARTICLE_CARD_HEADER_NAME,
  context: ArticleCardStyledContext,

  token: 'title.heading.medium',

  flex: 1,

  flexWrap: 'wrap',

  variants: {
    variant: {
      hero: {
        token: 'title.heading.xlarge',
      },
    },
  } as const,
});

const ArticleCardExcerpt = styled(BodyText, {
  name: ARTICLE_CARD_EXCERPT_NAME,
  context: ArticleCardStyledContext,

  token: 'body.default.medium',

  flexWrap: 'wrap',

  variants: {
    variant: {
      hero: {
        display: 'flex',
      },
    },
  } as const,
});

export const ArticleCard = withStaticProperties(ArticleCardFrame, {
  Header: ArticleCardHeader,
  Image: ArticleCardImage,
  Excerpt: ArticleCardExcerpt,
});
