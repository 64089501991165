import { FlashList } from '@shopify/flash-list';

import { Divider, Stack, isWeb } from '@arrived/bricks';
import { RefreshControl } from '@arrived/bricks-common';
import { Link } from '@arrived/router';

import { useGetArticlesByCategoryQuery } from '../../queries';
import { ArticleCard } from '../article';

type CategoryArticleListProps = Pick<ReturnType<typeof useGetArticlesByCategoryQuery>, 'data' | 'refetch'>;

const CategoryArticleLink = ({ data }: NonNullable<CategoryArticleListProps['data']>[number]) => (
  <Link href={`/blog/${data.slug}`} prefetch={false}>
    <ArticleCard
      $platform-native={{
        pointerEvents: 'none',
      }}
    >
      {data.featuredImage && <ArticleCard.Image title={data.title} featuredImage={data.featuredImage} />}
      <ArticleCard.Header tag="h4">{data.title}</ArticleCard.Header>
    </ArticleCard>
  </Link>
);

export const CategoryArticleList = ({ data, refetch }: CategoryArticleListProps) =>
  isWeb ? (
    <Stack rowGap="$8" columnGap="$6" $gtXxs={{ row: true, flexWrap: 'wrap' }}>
      {data?.map((item, index) => (
        <Stack
          key={`article-${item.id}-${index}`}
          flex={1}
          flexBasis="100%"
          $gtXxs={{ flexBasis: '50%' }}
          $gtXs={{ flexBasis: '25%' }}
        >
          <CategoryArticleLink {...item} />
        </Stack>
      ))}
    </Stack>
  ) : (
    <FlashList
      data={data}
      estimatedItemSize={350}
      refreshControl={<RefreshControl onRefresh={refetch} />}
      renderItem={({ item }) => <CategoryArticleLink {...item} />}
      ItemSeparatorComponent={() => <Divider solid alt py="$4" />}
    />
  );
