import { useForm } from 'react-hook-form';

import { Filter, SearchIcon, Stack } from '@arrived/bricks';
import { Input } from '@arrived/bricks-form';
import {
  ArrivedBuilderModels,
  BricksBuilderIcons,
  BuilderCategoryModel,
  GenericBuilderContent,
  RegisteredComponent,
} from '@arrived/builder.io';

interface CategoriesSearchProps {
  categories?: GenericBuilderContent<BuilderCategoryModel>[];
}

export const CategoriesSearch = ({ categories }: CategoriesSearchProps) => {
  const { control } = useForm();

  return (
    // @ts-expect-error overflow auto valid on web
    <Stack row gap="$2" alignItems="center" width="100%" overflow="auto" justifyContent="space-between">
      {/* @ts-expect-error height fit-content valid on web */}
      <Stack row gap="$2" h="fit-content">
        {categories?.map((categoryEntry, idx) => {
          if (!categoryEntry.data) {
            return null;
          }

          return <Filter key={`${categoryEntry.id}-${idx}`}>{categoryEntry.data.name}</Filter>;
        })}
      </Stack>
      <Stack p="$1" position={'sticky' as 'absolute'} right={0}>
        <Input control={control} name="search">
          <Input.Start ml="$2">
            <SearchIcon />
          </Input.Start>
        </Input>
      </Stack>
    </Stack>
  );
};

CategoriesSearch.registerComponent = {
  component: CategoriesSearch,
  name: 'CategoriesSearch',
  image: BricksBuilderIcons.Search,
  inputs: [
    {
      name: 'categories',
      helperText: 'List of categories to display',
      type: 'list',
      subFields: [
        {
          name: 'category',
          type: 'reference',
          model: ArrivedBuilderModels.CATEGORY,
        },
      ],
    },
  ],
} satisfies RegisteredComponent;
