import { Stack, TitleText } from '@arrived/bricks';
import { BuilderImage, RegisteredComponent } from '@arrived/builder.io';
import { Link } from '@arrived/router';

export type ArticlePreviewLinkProps = {
  featuredImage: string;
  title: string;
  slug: string;
};

export const ArticlePreviewLink = ({ featuredImage, title, slug }: ArticlePreviewLinkProps) => (
  <Link href={`/blog/${slug}`} prefetch={false}>
    <Stack gap="$4" $gtXxs={{ maxWidth: 250 }}>
      <Stack asChild className="article-image" width="100%" height="auto" borderRadius="$2" overflow="hidden">
        <BuilderImage aspectRatio="4/3" source={{ uri: featuredImage }} alt={title} />
      </Stack>

      <TitleText tag="h3" token="title.heading.small">
        {title}
      </TitleText>
    </Stack>
  </Link>
);

ArticlePreviewLink.registerComponent = {
  component: ArticlePreviewLink,
  name: 'Article Preview',
  inputs: [
    {
      name: 'title',
      type: 'longText',
    },
    {
      name: 'featuredImage',
      type: 'file',
    },
    {
      name: 'slug',
      type: 'longText',
    },
  ],
} satisfies RegisteredComponent;
