import { useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { BodyText, Stack, TitleText, UtilityText } from '@arrived/bricks';
import { htmlParser } from '@arrived/bricks-common';
import { BricksBuilderIcons, RegisteredComponent } from '@arrived/builder.io';
import { AnswerText, CommonQuestions, CommonQuestionsProps, QuestionTitle } from '@arrived/features-product-details';

import { IntercomHelpCenter } from './intercom-help-center';

type FAQSectionProps = {
  faqs: {
    question?: string;
    answer?: string;
  }[];
};

export const FAQSection = ({ faqs }: FAQSectionProps) => {
  const questions = useMemo<CommonQuestionsProps['questions']>(
    () =>
      faqs
        ? faqs.map(({ question, answer }) => ({
            Question: (props) =>
              question ? (
                <QuestionTitle {...props}>{htmlParser(question)}</QuestionTitle>
              ) : (
                <>Question text will appear here</>
              ),
            Answer: (props) =>
              answer ? <AnswerText {...props}>{htmlParser(answer)}</AnswerText> : <>Answer text will appear here</>,
          }))
        : [],
    [faqs],
  );

  return (
    <Stack gap="$4" marginVertical="$6">
      <TitleText tag="h2" token="title.heading.medium">
        <FormattedMessage id="blog.faq.section.title" />
      </TitleText>

      <CommonQuestions questions={questions} />

      <BodyText>
        <FormattedMessage
          id="blog.faq.more-questions"
          values={{
            faqLink: (parts) => (
              <IntercomHelpCenter>
                <UtilityText token="utility.hyperlink.medium">{parts}</UtilityText>
              </IntercomHelpCenter>
            ),
          }}
        />
      </BodyText>
    </Stack>
  );
};

FAQSection.registerComponent = {
  component: FAQSection,
  name: 'FAQSection',
  image: BricksBuilderIcons.Help,
  inputs: [
    {
      name: 'faqs',
      friendlyName: 'FAQ Section',
      type: 'list',
      subFields: [
        {
          name: 'question',
          type: 'richText',
          required: true,
        },
        {
          name: 'answer',
          type: 'richText',
          required: true,
        },
      ],
    },
  ],
} satisfies RegisteredComponent;
