import { useCallback, useMemo } from 'react';

import { format } from 'date-fns';

import { BodyText, Divider, Stack, Tag, isWeb } from '@arrived/bricks';
import { BuilderImage, transformBuilderImage } from '@arrived/builder.io';
import { useRouter } from '@arrived/router';

import { UseGetBlogArticleQueryResult } from '../../queries';
import { useCategoriesNavigationContext } from '../categories';

type ArticleAuthorProps = {
  image?: string;
  title?: string;
};

/**
 * Displays the author of an article, including their name and avatar.
 */
export const ArticleAuthor = ({ image, title }: ArticleAuthorProps) => {
  const imageUrl = useMemo(
    () => (image ? transformBuilderImage(image, { width: 24, height: 24 }) : undefined),
    [image],
  );

  return (
    <Stack className="blog-signature" gap="$2" row alignItems="center">
      <Stack width="$6" height="$6" borderRadius="$full">
        <BuilderImage aspectRatio="1" source={{ uri: imageUrl! }} alt={title} />
      </Stack>

      <BodyText token="body.compact.small" color="$onSurface.neutral.muted" numberOfLines={1}>
        {title}
      </BodyText>
    </Stack>
  );
};

const LinkedTag = ({ href, name }: { href: string; name: string }) => {
  const { push } = useRouter();

  const { setActiveCategorySlug } = useCategoriesNavigationContext();

  const handlePress = useCallback(() => {
    // On native, we need to set the active category slug in the context
    if (!isWeb) {
      const category = href.split('/').pop();

      if (category) {
        setActiveCategorySlug(category);
      }

      push(href, '/blog');
    } else {
      push(href);
    }
  }, [href, setActiveCategorySlug, push]);

  return (
    <Tag condensed pressable variant="category" aria-label={name} onPress={handlePress}>
      <Tag.Label>{name}</Tag.Label>
    </Tag>
  );
};

type ArticleTitleMetadataProps = {
  post: NonNullable<UseGetBlogArticleQueryResult['data']>;
};

/**
 * Header component that displays the title, author, date and tags of a blog post.
 */
export const ArticleTitleMetadata = ({ post }: ArticleTitleMetadataProps) => {
  const createdAt = useMemo(() => {
    if (!post.createdDate) {
      return null;
    }

    return format(new Date(post.createdDate), 'PP');
  }, [post.createdDate]);

  const authorsList = useMemo(
    () => post.data?.authors?.filter((entry) => entry.author.value) ?? [],
    [post.data?.authors],
  );

  const categoriesList = useMemo(
    () => post.data?.categories?.filter((entry) => entry.category.value) ?? [],
    [post.data?.categories],
  );

  return (
    <Stack
      row
      gap="$2"
      alignItems="center"
      flexWrap="wrap"
      $platform-web={{
        // Fixes weird issue where the width of the tags don't wrap
        flexShrink: 1,
      }}
    >
      {authorsList.map((authorEntry, idx) => (
        <ArticleAuthor
          key={`author-list-${authorEntry.id}-${idx}`}
          title={authorEntry.author.value?.data.authorName}
          image={authorEntry.author.value?.data.authorAvatar}
        />
      ))}

      {Boolean(authorsList.length && createdAt) && (
        <Stack asChild h="$3">
          <Divider.Vertical solid alt />
        </Stack>
      )}

      {Boolean(createdAt) && (
        <BodyText tag="p" color="$onSurface.neutral.muted" token="body.compact.small">
          {createdAt}
        </BodyText>
      )}

      {Boolean(createdAt && categoriesList && categoriesList.length !== 0) && (
        <Stack asChild h="$3">
          <Divider.Vertical solid alt />
        </Stack>
      )}

      <Stack className="categories-list" row gap="$2" flex={1}>
        {categoriesList?.map(({ category }, idx) => {
          if (!category.value) {
            return null;
          }

          const { name, slug } = category.value.data;

          return (
            <LinkedTag
              key={`category-list-${category.id}-${slug}-${idx}`}
              href={`/blog/category/${slug}`}
              name={name}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};
