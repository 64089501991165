import { useQuery } from '@tanstack/react-query';

import { ArrivedBuilderModels, BuilderCategoryModel, FetchOneEntryParams, fetchOneEntry } from '@arrived/builder.io';
import { CONFIG } from '@arrived/config';

import { builderCategoryQueryKey } from './keys';

export type FetchCategoryQuery = {
  categorySlug?: string;
} & FetchOneEntryParams;

export const fetchCategory = ({ categorySlug, ...rest }: FetchCategoryQuery) =>
  fetchOneEntry<BuilderCategoryModel>({
    apiKey: CONFIG.builderApiKey,
    model: ArrivedBuilderModels.CATEGORIES,
    query: {
      'data.slug': categorySlug,
    },
    ...rest,
  });

export const useGetCategoryQuery = (params: FetchCategoryQuery) =>
  useQuery({
    queryKey: builderCategoryQueryKey(params),
    queryFn: () => fetchCategory(params),
    enabled: Boolean(params.categorySlug),
  });

export type useGetCategoryQueryResult = ReturnType<typeof useGetCategoryQuery>;
