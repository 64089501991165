import { Divider as BricksDivider } from '@arrived/bricks';
import { BricksBuilderIcons, RegisteredComponent } from '@arrived/builder.io';

type DividerProps = {
  vertical: boolean;
  solid: boolean;
  alt: boolean;
};

export const Divider = ({ vertical, solid, alt }: DividerProps) => {
  if (vertical) {
    return <BricksDivider.Vertical solid={solid} alt={alt} />;
  }

  return <BricksDivider solid={solid} alt={alt} />;
};

Divider.registerComponent = {
  component: Divider,
  name: 'Divider',
  image: BricksBuilderIcons.VerticalDots,
  inputs: [
    {
      name: 'vertical',
      type: 'boolean',
    },
    {
      name: 'solid',
      type: 'boolean',
    },
    {
      name: 'alt',
      type: 'boolean',
    },
  ],
} satisfies RegisteredComponent;
