import { ReactNode, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { Button, Stack, TitleText } from '@arrived/bricks';
import { htmlParser } from '@arrived/bricks-common';
import {
  ArrivedBuilderModels,
  BricksBuilderIcons,
  GenericBuilderBlockReference,
  RegisteredComponent,
} from '@arrived/builder.io';
import { Link } from '@arrived/router';

import { BuilderBlogArticleModel } from '../models';

import { ArticleCard, ArticleTitleMetadata } from './article';

type FeaturedArticleProps = {
  header?: ReactNode;
  selectedFeaturedPost: GenericBuilderBlockReference<BuilderBlogArticleModel>;
};

export const FeaturedArticle = ({ header, selectedFeaturedPost }: FeaturedArticleProps) => {
  const articleSlug = useMemo(() => selectedFeaturedPost?.value.data?.slug, [selectedFeaturedPost]);

  if (!selectedFeaturedPost?.value.data) {
    return null;
  }

  return (
    <Link href={`/blog/${articleSlug}`}>
      <Stack
        gap="$4"
        mb="$6"
        $gtSm={{ mb: 0 }}
        $platform-native={{
          // Button events will override the link events
          pointerEvents: 'none',
        }}
      >
        {Boolean(header) && (
          <TitleText tag="h2" token="title.heading.small">
            {header}
          </TitleText>
        )}

        <ArticleCard variant="hero" gap="$2">
          {selectedFeaturedPost.value?.data.featuredImage && (
            <ArticleCard.Image
              title={selectedFeaturedPost.value.data.title}
              featuredImage={selectedFeaturedPost.value.data.featuredImage}
              $platform-native={{
                flexShrink: 1,
                width: '100%',
                height: '100%',
              }}
            />
          )}

          <ArticleCard.Header tag="h3">{selectedFeaturedPost.value?.data.title}</ArticleCard.Header>

          <ArticleTitleMetadata post={selectedFeaturedPost.value} />

          {Boolean(selectedFeaturedPost.value?.data.excerpt) && (
            <ArticleCard.Excerpt>{htmlParser(selectedFeaturedPost.value?.data.excerpt)}</ArticleCard.Excerpt>
          )}

          <Stack alignItems="flex-start">
            <Button variant="outlined">
              <FormattedMessage id="blog.read-more" />
            </Button>
          </Stack>
        </ArticleCard>
      </Stack>
    </Link>
  );
};

FeaturedArticle.registerComponent = {
  component: FeaturedArticle,
  name: 'FeaturedPost',
  image: BricksBuilderIcons.Star,
  inputs: [
    { name: 'header', type: 'text' },
    {
      name: 'selectedFeaturedPost',
      friendlyName: 'Selected Featured Post',
      helperText: 'Choose a blog article to feature',
      type: 'reference',
      model: ArrivedBuilderModels.BLOG_ARTICLE,
    },
  ],
  models: ['page'],
} satisfies RegisteredComponent;
