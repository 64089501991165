import { useQuery } from '@tanstack/react-query';

import { ArrivedBuilderModels, BuilderCategoryModel, FetchEntriesParams, fetchEntries } from '@arrived/builder.io';
import { CONFIG } from '@arrived/config';

import { builderCategoriesSymbolQueryKey } from './keys';

export const fetchCategories = (params?: FetchEntriesParams) =>
  fetchEntries<BuilderCategoryModel>({
    apiKey: CONFIG.builderApiKey,
    model: ArrivedBuilderModels.CATEGORIES,
    ...params,
  });

export const useGetCategoriesQuery = (params?: FetchEntriesParams) =>
  useQuery({
    queryKey: builderCategoriesSymbolQueryKey(params),
    queryFn: () => fetchCategories(params),
  });

export type UseGetCategoriesQueryResult = ReturnType<typeof useGetCategoriesQuery>;
